<template>
  <ion-app>
    <ion-split-pane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="menu-list">
            <ion-menu-toggle :auto-hide="false">
              <ion-item
                router-direction="root"
                :router-link="{ name: 'home' }"
                :detail="false"
                class="hydrated"
              >
                <img id="ftl-menu-logo" :src="logoPath" alt="FTL Logo" />
              </ion-item>
            </ion-menu-toggle>

            <ion-menu-toggle
              :auto-hide="false"
              v-for="(p, i) in menuItems"
              :key="i"
            >
              <ion-item
                router-direction="root"
                :router-link="p.route"
                :href="p.url"
                lines="none"
                :detail="false"
                class="hydrated"
                :class="{ selected: isSelected(p.route) }"
              >
                <ion-icon slot="start" :icon="p.icon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-item v-if="isAuthenticated" lines="none" class="user">
            <ion-avatar v-if="photo" slot="start">
              <img :src="photo" />
            </ion-avatar>
            <ion-icon v-else :icon="personCircleSharp" slot="start"></ion-icon>

            <ion-label>{{ name }}</ion-label>
          </ion-item>
        </ion-content>
        <ion-footer>
          <ion-toolbar class="ion-text-center">
            <a href="/privacy-policy.html" target="_blank">privacy policy</a>
          </ion-toolbar>
        </ion-footer>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>

<script lang="ts">
import {
  IonApp,
  IonAvatar,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonToolbar,
} from '@ionic/vue';
import {
  bookmarkSharp,
  calendarSharp,
  documentTextSharp,
  logInSharp,
  logOutSharp,
  newspaperSharp,
  personCircleSharp,
  shirtSharp,
  tennisballSharp,
} from 'ionicons/icons';
import { computed, defineComponent, ref, watchEffect } from 'vue';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';

import { useAnalytics } from '@/store/analytics';
import { useAuth } from '@/store/auth';
import { useMessaging } from '@/store/messaging';
import { useSwUpdate } from '@/store/sw-update';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonAvatar,
    IonContent,
    IonFooter,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonToolbar,
  },
  setup() {
    const { init: initAnalytics } = useAnalytics();
    const { isAuthenticated, name, photo } = useAuth();
    const { init: initMessaging } = useMessaging();
    const route = useRoute();
    const router = useRouter();
    const { init: initSwUpdate } = useSwUpdate();

    initSwUpdate();
    initAnalytics();
    initMessaging();

    const logoPath = computed(() =>
      route.name === 'home'
        ? '/img/ftl-logo-white.svg'
        : '/img/ftl-logo-color.svg',
    );

    interface MenuItem {
      title: string;
      route?: RouteLocationRaw;
      url?: string;
      icon: string;
    }

    const menuItems = ref<MenuItem[]>([]);

    watchEffect(() => {
      const newMenuItems: MenuItem[] = [
        {
          title: 'All Seasons',
          route: { name: 'seasons' },
          icon: calendarSharp,
        },
        {
          title: 'Rules',
          route: { name: 'rules' },
          icon: documentTextSharp,
        },
        {
          title: 'Blog',
          url: 'https://blog.nextgenftl.com',
          icon: newspaperSharp,
        },
        {
          title: 'FTL Merch',
          url: 'https://ftlmerch.etsy.com/',
          icon: shirtSharp,
        },
      ];
      if (isAuthenticated.value) {
        newMenuItems.push({
          title: 'My Teams',
          route: { name: 'my-teams' },
          icon: tennisballSharp,
        });
        newMenuItems.push({
          title: 'Sign Out',
          route: { name: 'logout' },
          icon: logOutSharp,
        });
      } else {
        newMenuItems.push({
          title: 'Sign In',
          route: { name: 'login' },
          icon: logInSharp,
        });
      }
      menuItems.value = newMenuItems;
    });

    return {
      menuItems,
      isAuthenticated,
      isSelected: (r?: RouteLocationRaw) =>
        r && router.resolve(r).path === route.path ? 'selected' : '',
      logoPath,
      name,
      photo,
      // icons
      bookmarkSharp,
      documentTextSharp,
      personCircleSharp,
    };
  },
});
</script>

<style scoped>
#ftl-menu-logo {
  height: 7em;
}

/* adjust widths on small screens */
@media (max-width: 576px) {
  #ftl-menu-logo {
    height: 5em;
  }
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu ion-item {
  cursor: pointer;
}

ion-menu ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu ion-list {
  padding: 20px 0;
}

ion-menu ion-note {
  margin-bottom: 30px;
}

ion-menu ion-list-header,
ion-menu ion-note {
  padding-left: 10px;
}

ion-menu ion-list#menu-list {
  border-bottom: 1px solid var(--ion-color-step-150);
}

ion-menu ion-list#menu-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: var(--ion-color-step-400);

  min-height: 26px;
}

ion-menu ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu ion-item ion-icon {
  color: var(--ion-color-step-300);
}

ion-menu ion-item ion-label {
  font-weight: 500;
}

ion-list#menu-list ion-item ion-label {
  font-family: var(--ftl-title-font);
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
