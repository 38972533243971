import { useAppNotification } from './app-notification';

const { toastNotification } = useAppNotification();

function init() {
  // Listen for our custom event from the SW registration
  document.addEventListener('swUpdated', updateAvailable as EventListener, {
    once: true,
  });
}

function updateAvailable(event: CustomEvent) {
  const updateSW = event.detail as (reloadPage?: boolean | undefined) => void;
  toastNotification({
    message: 'A new version of the FTL app is available. Are you excited?!',
    buttons: [{ text: 'Yep!', handler: updateSW }],
  });
}

export function useSwUpdate() {
  return {
    init,
  };
}
