import {
  logEvent as fbLogEvent,
  getAnalytics,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import { watchEffect } from 'vue';

import { useAuth } from '@/store/auth';
import { useFirebase } from '@/store/firebase';

const { firebaseApp } = useFirebase();

const analytics = getAnalytics(firebaseApp);
const { name, userId } = useAuth();

watchEffect(() => {
  if (userId.value) {
    setUserId(analytics, userId.value);
    setUserProperties(analytics, {
      name: name.value,
    });
  }
});

function init() {
  // nothing to do for now
}

function logEvent(eventName: string, params?: Record<string, unknown>) {
  fbLogEvent(analytics, eventName, params);
}

export function useAnalytics() {
  return {
    //actions
    init,
    logEvent,
  };
}
