export interface MessagingSubscription {
  userId: string;
  tokens: Token[];
  topics: Topic[];
  createdAt: Date;
  updatedAt: Date;
}

export interface Token {
  token: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum Topic {
  GAME_WEEK_OPENED = 'game-week-opened',
  LOCKOUT_REMINDER_ONE_DAY = 'lockout-reminder-1d',
  LOCKOUT_REMINDER_TWO_HOURS = 'lockout-reminder-2h',
  FTL_NEWS_UPDATE = 'ftl-news-update',
}
