import {
  alertController,
  AlertOptions,
  toastController,
  ToastOptions,
} from '@ionic/vue';

async function alertNotification(options: AlertOptions) {
  const alert = await alertController.create(options);
  await alert.present();
  return alert;
}

async function toastNotification(options: ToastOptions) {
  const toast = await toastController.create(options);
  await toast.present();
  return toast;
}

// convenience functions

function alertError(msg: string) {
  return alertNotification({
    message: msg,
    buttons: [
      {
        text: 'OK',
        role: 'cancel',
      },
    ],
  });
}

function toastError(msg: string) {
  return toastNotification({
    message: msg,
    color: 'danger',
    duration: 3000,
  });
}

function toastSuccess(msg: string) {
  return toastNotification({
    message: msg,
    color: 'success',
    duration: 3000,
  });
}

export function useAppNotification() {
  return {
    alertError,
    alertNotification,
    toastNotification,
    toastError,
    toastSuccess,
  };
}
