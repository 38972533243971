import {
  CollectionReference,
  connectFirestoreEmulator,
  DocumentReference,
  initializeFirestore,
  onSnapshot,
  persistentLocalCache,
  persistentMultipleTabManager,
  Query,
  QueryDocumentSnapshot,
  Unsubscribe,
} from 'firebase/firestore';
import { ref, Ref, watchEffect } from 'vue';

import { useFirebase } from '@/store/firebase';

import { useAuth } from './auth';

const { firebaseApp } = useFirebase();
const { isAuthenticated } = useAuth();

const firestore = initializeFirestore(firebaseApp, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
});

const emulatorHost = import.meta.env.VITE_FIRESTORE_EMULATOR_HOST;
if (emulatorHost) {
  console.log(`using firestore emulator host ${emulatorHost}`);
  const [host, port] = emulatorHost.split(':');
  connectFirestoreEmulator(firestore, host, port);
}

function generateCollectionWatchEffect<T>(
  collectionRef: Ref<CollectionReference<T> | undefined>,
  loadingRefsArray: Ref<boolean>[] | undefined,
  requireAuth = true,
) {
  let unsubscriber: Unsubscribe | undefined;
  let cachedPath: string | undefined;
  const generatedRef = ref<Array<T & { id: string }>>();
  const loading = ref(false);
  if (Array.isArray(loadingRefsArray)) {
    loadingRefsArray.push(loading);
  }

  watchEffect(() => {
    if (!collectionRef.value || (requireAuth && !isAuthenticated.value)) {
      // no user logged in. reset values.
      unsubscriber?.();
      generatedRef.value = [];
      return;
    }
    const path = collectionRef.value.path;
    if (path === cachedPath && unsubscriber) {
      // already have a realtime listener attached for this id.
      // nothing to do here.
      return;
    }

    // unsubscribe from the previous listener, if applicable.
    unsubscriber?.();
    loading.value = true;

    unsubscriber = onSnapshot(collectionRef.value, (snapshot) => {
      const newStuff: Array<T & { id: string }> = [];
      snapshot.forEach((doc) => {
        newStuff.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      generatedRef.value = newStuff;
      loading.value = false;
      cachedPath = path;
    });
  });

  return generatedRef;
}

function generateDocWatchEffect<T>(
  docRef: Ref<DocumentReference<T> | undefined>,
  defaultValue: T | undefined,
  loadingRefsArray: Ref<boolean>[] | undefined,
  requireAuth = true,
) {
  let unsubscriber: Unsubscribe | undefined;
  let cachedPath: string | undefined;
  const generatedRef = ref<T>();
  const loading = ref(false);
  if (Array.isArray(loadingRefsArray)) {
    loadingRefsArray.push(loading);
  }

  watchEffect(() => {
    if (!docRef.value || (requireAuth && !isAuthenticated.value)) {
      // no user logged in. reset values.
      unsubscriber?.();
      generatedRef.value = defaultValue;
      return;
    }
    const path = docRef.value.path;
    if (path === cachedPath && unsubscriber) {
      // already have a realtime listener attached for this id.
      // nothing to do here.
      return;
    }

    // unsubscribe from the previous listener, if applicable.
    unsubscriber?.();
    loading.value = true;

    unsubscriber = onSnapshot(docRef.value, (snapshot) => {
      generatedRef.value = snapshot.data() ?? defaultValue;
      loading.value = false;
      cachedPath = path;
    });
  });

  return generatedRef;
}

// TODO: fix this crazy T2 typing if possible
function generateQueryWatchEffect<T, T2 = T & { id: string }>(
  query: Ref<Query<T> | undefined>,
  loadingRefsArray: Ref<boolean>[] | undefined,
  requireAuth = true,
  docTransformer: (doc: QueryDocumentSnapshot<T>) => T2 = (doc) =>
    ({
      ...doc.data(),
      id: doc.id,
    }) as T2,
): Ref<T2[] | undefined> {
  let unsubscriber: Unsubscribe | undefined;
  const generatedRef = ref<Array<T2>>();
  const loading = ref(false);
  if (Array.isArray(loadingRefsArray)) {
    loadingRefsArray.push(loading);
  }

  watchEffect(() => {
    if (!query.value || (requireAuth && !isAuthenticated.value)) {
      // no user logged in. reset values.
      unsubscriber?.();
      generatedRef.value = [];
      return;
    }

    // unsubscribe from the previous listener, if applicable.
    unsubscriber?.();
    loading.value = true;

    unsubscriber = onSnapshot(query.value, (snapshot) => {
      const newStuff: Array<T2> = [];
      snapshot.forEach((doc) => {
        newStuff.push(docTransformer(doc));
      });
      generatedRef.value = newStuff;
      loading.value = false;
    });
  });

  return generatedRef;
}

export function useFirestore() {
  return {
    //state
    firestore,
    //actions
    generateCollectionWatchEffect,
    generateDocWatchEffect,
    generateQueryWatchEffect,
  };
}
