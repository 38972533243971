import { Storage } from '@ionic/storage';

let _store: Storage | undefined;

async function _lazyInit() {
  if (!_store) {
    _store = new Storage();
    await _store.create();
  }
  return _store;
}

async function getKey<T = unknown>(key: string): Promise<T | undefined> {
  const store = await _lazyInit();
  return store.get(key);
}

async function setKey<T = unknown>(key: string, value: T): Promise<T> {
  const store = await _lazyInit();
  return store.set(key, value);
}

export function useStorage() {
  return {
    getKey,
    setKey,
  };
}
